/* Generated by script */
@font-face {
  font-family: Vazirmatn FD;
  src: url("https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@33.003/misc/Farsi-Digits/fonts/webfonts/Vazirmatn-FD-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Vazirmatn FD;
  src: url("https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@33.003/misc/Farsi-Digits/fonts/webfonts/Vazirmatn-FD-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Vazirmatn FD;
  src: url("https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@33.003/misc/Farsi-Digits/fonts/webfonts/Vazirmatn-FD-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Vazirmatn FD;
  src: url("https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@33.003/misc/Farsi-Digits/fonts/webfonts/Vazirmatn-FD-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Vazirmatn FD;
  src: url("https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@33.003/misc/Farsi-Digits/fonts/webfonts/Vazirmatn-FD-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Vazirmatn FD;
  src: url("https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@33.003/misc/Farsi-Digits/fonts/webfonts/Vazirmatn-FD-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Vazirmatn FD;
  src: url("https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@33.003/misc/Farsi-Digits/fonts/webfonts/Vazirmatn-FD-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Vazirmatn FD;
  src: url("https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@33.003/misc/Farsi-Digits/fonts/webfonts/Vazirmatn-FD-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Vazirmatn FD;
  src: url("https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@33.003/misc/Farsi-Digits/fonts/webfonts/Vazirmatn-FD-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html,
  body {
    @apply [&::-webkit-scrollbar-thumb]:bg-gray-400
        dark:[&::-webkit-scrollbar-thumb]:bg-slate-500
        [&::-webkit-scrollbar-track]:bg-transparent
        [&::-webkit-scrollbar]:w-3;
  }
  [lang=fa] html,
  [lang=fa] body {
    @apply font-vazirmatn-fd;
  }
}
@layer components {
  .vbox-container {
    @apply overflow-y-auto [&::-webkit-scrollbar-thumb]:bg-gray-400
        dark:[&::-webkit-scrollbar-thumb]:bg-slate-500
        [&::-webkit-scrollbar-track]:bg-transparent
        [&::-webkit-scrollbar]:w-3;
  }
  .alert-success {
    @apply rounded-lg border border-teal-200 bg-teal-100 p-4 text-sm text-teal-800 dark:border-teal-900 dark:bg-teal-800/10 dark:text-teal-500;
  }
  .alert-danger {
    @apply rounded-lg border border-red-200 bg-red-100 p-4 text-sm text-red-800 dark:border-red-900 dark:bg-red-800/10 dark:text-red-500;
  }
}